import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';


import AdultsBanner from '../../img/adults/adults_program_banner.jpg'
import AdultsSession from '../../img/adults/adults_program_session.jpg'

export const AdultsPageTemplate = ({  }) => {
  
  // PageContent = contentComponent || Content

  return (
    <section className="adults-landing-page">

      {/* Section 1 */}
      <Grid container
        direction="row"
        justify="center"
        className="hero-img-wrap none mb-block"
      >
        <Grid item xs={12} sm={12} md={12} >
        <img src={AdultsBanner} alt="Adults Program"/>
        </Grid>
      </Grid>

    <Grid container
        direction="row"
        justify="left"
        className="hero-text-wrap"
      >
        <Grid item xs={12} sm={12} md={6} className="mb-none" >
          <h1 className="main-title mb-mb-0 text-left pl-2">Communicate <br/> with <br/> Confidence!</h1>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container
              direction="row"
              justify="center"
              >
            <Grid item xs={12} sm={12} md={12}>
              <div className="bg-secondary text-white adults-info-card">
                <h4 className="text-white">Are you tired of the inability to express yourself?</h4>
                <h4 className="text-white">Do you want to stand out from the rest? </h4>
                <h3 className="text-white mt-1"><b>Then join our course! </b> </h3>
                <p className="text-white mt-2">With us you’ll learn how to speak with confidence, overcome nervousness, and become an effective communicator. </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="mb-none">
              <div className="bg-primary text-white adults-info-card">
                  <Link to="#programInfo">
                    <p className="text-white">Ready to face to challenges of the corporate world!</p>
                    <h3 className='text-white'>Discover More
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" class="bi bi-arrow-up-right-square" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"/>
                      </svg>
                    </h3>
                  </Link>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <div className="bg-accent text-white adults-info-card">
                <Link to="https://wa.me/94777768769" aria-label="Chat on WhatsApp">
                    <p className="text-white none mb-block">Sign up now and be ready to face to challenges of the corporate world!</p>
                    <p className="text-white mb-none">Sign up now!</p>
                    <h3 className='text-white'>Start a Chat
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" class="bi bi-arrow-up-right-square" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"/>
                      </svg>
                    </h3>
                  </Link>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid> 

      <Grid container
        direction="row"
        justify="center"
        className="hero-img-wrap mb-none"
      >
        <Grid item xs={12} sm={12} md={12} >
        <img src={AdultsBanner} alt="Adults Program"/>
        </Grid>
      </Grid>


      <Grid container
            direction="row"
            spacing={3}
            className="bg-primary text-primary p-2"
            id="programInfo"
          >
            <Grid item xs={12} sm={12} md={3} >
              <h2 style={{fontSize:"24px", fontWeight:"bold"}} className="text-primary mt-1">Course Duration</h2>
              <p className="text-primary">&bull; Six weeks</p>
              <p className="text-primary">&bull; 60 min online sessions</p>
            </Grid>
            <Grid item xs={12} sm={12} md={5} >
              <h2 style={{fontSize:"24px", fontWeight:"bold"}} className="text-primary mt-1">Prerequisites</h2>
              <p className="text-primary">&bull; The course is for School leavers and working professionals.</p>
              <p className="text-primary">&bull; A zoom interview will be scheduled to assess your voice and understand the individual needs of each student prior to enrolment.</p>
            </Grid>
            <Grid item xs={12} sm={12} md={4} >
              <h2 style={{fontSize:"24px", fontWeight:"bold"}} className="text-primary mt-1">Course Fee</h2>
              <p className="text-primary">&bull; Group - 20,000 LKR (6 sessions, up to 12 pax)</p>
              <p className="text-primary">&bull; Individual (15,000 per session, course duration may vary, minimum 4 sessions)</p>
              <p className="text-primary mb-mb-2">&bull; Dual or private groups – Contact us for rates</p>
            </Grid>
          </Grid>

      <Grid container
        spacing={3}
      >

      <Grid item xs={12} sm={12} md={4}>
      <p className="page-description mb-mt-0 adults-program-info mb-pb-0 text-justify">In today&#39;s fast-paced world, the ability to effectively communicate and express one&#39;s self
is more important than ever before. Whether it&#39;s in personal relationships, professional
settings, or even in social media interactions, the way we communicate can greatly
impact our success and fulfillment in life.</p>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
      <p className="page-description mb-mt-0 adults-program-info mb-pt-0 mb-pb-0 text-justify">This course is designed to help you explore
your own unique voice and develop the skills necessary to effectively communicate
your thoughts, ideas, and emotions to others. </p>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
      <p className="page-description mb-mt-0 adults-program-info  mb-pt-0 text-justify">Through a combination of theoretical
frameworks, practical exercises, and personalized feedback, we aim to help you
become a more confident and effective communicator, both in your personal and
professional life. So, let&#39;s begin this journey of self-discovery and growth together!</p>
      </Grid>
      </Grid>


      <Grid container
        direction="row"
        justify="center"
      >
        <Grid item xs={12} sm={12} md={12} >
        <img src={AdultsSession} className="adults-banner-2" alt="Adults Program Session"/>
        </Grid>
      </Grid>


      {/* Section 2 */}
      <Grid container
        direction="row"
        justify="center"
        className="mt-5 mb-mt-2 mb-px-1"
      >

        <Grid item xs={12} sm={12} md={12} >
            <h1 className="main-title mb-text-center mb-mb-0 mb-mt-2 mb-mx-0 text-center">Course Objectives</h1>
        </Grid>
      </Grid>

      <Grid container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} sm={12} md={8} className="mb-px-2" >
        <Grid container
            direction="row"
            justify="center"
            spacing={3}
            className="mt-3 bg-secondary onstage-card box-shadow border-radius-6 p-2"
          >
            <Grid item xs={12} sm={12} md={12}>
              <p>Be more confident about your voice and its possibilities</p>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            justify="center"
            spacing={3}
            className="mt-3 bg-primary onstage-card box-shadow border-radius-6 p-2"
          >
            <Grid item xs={12} sm={12} md={12}>
              <p>Deliver your message clearly and effectively</p>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            justify="center"
            spacing={3}
            className="mt-3 bg-accent onstage-card box-shadow border-radius-6 p-2"
            >
            <Grid item xs={12} sm={12} md={12}>
              <p>Use your voice as a means to express yourself authentically</p>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            justify="center"
            spacing={3}
            className="mt-3 bg-secondary onstage-card box-shadow border-radius-6 p-2"
            >
            <Grid item xs={12} sm={12} md={12}>
              <p>Know how to deal with circumstances (emotions and stress) affecting your voice</p>
            </Grid>
          </Grid>

        </Grid>
      </Grid>

      {/* Section 3 */}
      <Grid container
        direction="row"
        justify="center"
        className="mt-5 mb-mt-2 mb-px-1"
      >
        <Grid item xs={12} sm={12} md={12} className="mb-mt-2" >
          <h1 className="main-title mb-mb-0 text-center">Topics that will be covered</h1>
        </Grid>
      </Grid>

      <Grid container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
        className="mt-2 mb-4 mb-mt-0"
      >

        <Grid item xs={12} sm={12} md={2} className="mb-px-2 text-center" >
         
         </Grid>
        <Grid item xs={12} sm={12} md={8} className="mb-px-2" >
          <p className='page-description'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#199d95" class="bi bi-cursor-fill" viewBox="0 0 16 16">
              <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z"/>
            </svg>
            <span className="ml-1 bold">Voice techniques (Pausing, emphasizing,intonation, pace and rhythm)</span> 
          </p>

          <p className='mt-1 page-description'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#199d95" class="bi bi-cursor-fill" viewBox="0 0 16 16">
              <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z"/>
            </svg>
            <span className="ml-1">Articulation and diction</span> 
          </p>

          <p className='mt-1 page-description'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#199d95" class="bi bi-cursor-fill" viewBox="0 0 16 16">
              <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z"/>
            </svg>
            <span className="ml-1">Resonance ad Voice projection</span> 
          </p>

          
          <p className='mt-1 page-description'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#199d95" class="bi bi-cursor-fill" viewBox="0 0 16 16">
              <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z"/>
            </svg>
            <span className="ml-1">Reading aloud effectively</span> 
          </p>
          
          <p className='mt-1 page-description'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#199d95" class="bi bi-cursor-fill" viewBox="0 0 16 16">
              <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z"/>
            </svg>
            <span className="ml-1">Body Language and gestures</span> 
          </p>

          <p className='mt-1 mb-4 page-description'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#199d95" class="bi bi-cursor-fill" viewBox="0 0 16 16">
              <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z"/>
            </svg>
            <span className="ml-1">Relaxation techniques</span> 
          </p>

         </Grid>
      </Grid>


    </section>
  )
}

AdultsPageTemplate.propTypes = {}

const AdultsPage = ({ }) => {
  return (
    <Layout isProgramTypeSelected= {true}>
      <AdultsPageTemplate

      />
    </Layout>
  )
}

AdultsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AdultsPage
